const dropdownlocations = [
  {
    name: 'bakersfield',
    value: 'bakersfield'
  },
  {
    name: 'fresno',
    value: 'fresno'
  },
  {
    name: 'hanford',
    value: 'hanford'
  },
  {
    name: 'imperial co',
    value: 'imperial'
  },
  {
    name: 'inland empire',
    value: 'inlandempire'
  },
  {
    name: 'las vegas',
    value: 'lasvegas'
  },
  {
    name: 'los angeles',
    value: 'losangeles'
  },
  {
    name: 'orange co',
    value: 'orangecounty'
  },
  {
    name: 'palm springs',
    value: 'palmsprings'
  },
  {
    name: 'san diego',
    value: 'sandiego'
  },
  {
    name: 'san luis obispo',
    value: 'slo'
  },
  {
    name: 'santa barbara',
    value: 'santabarbara'
  },
  {
    name: 'santa maria',
    value: 'santamaria'
  },
  {
    name: 'tijuana',
    value: 'tijuana'
  },
  {
    name: 'ventura',
    value: 'ventura'
  },
  {
    name: 'visalia-tulare',
    value: 'visalia'
  },
  {
    name: 'yuma',
    value: 'yuma'
  },
];

export default dropdownlocations;