import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import './Header.css';
import LocationDropdown from '../LocationDropdown/LocationDropdown';

const Header = () => {
  return (
    <div className='Header'>
      <Container>
        <Row>
          <Col xs='12'>
            <div className='top-header'>
              <a href='https://www.craigslist.org'><h1 className='logo'>craigslist</h1></a>
              <div id='my-account'>
                <h5>my account</h5>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs='12'>
            <label className="search-bar">
              <input type="text" placeholder=' ' />
              <span className="label">search on craigslist</span>
              <span className="borderline"></span>
            </label>
            <LocationDropdown />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Header;