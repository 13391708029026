import React, {Component} from 'react';
import { Collapse } from 'reactstrap';
import './AccordionPanel.css';

import AccordionDescription from './Components/AccordionDescription/AccordionDescription';
import SubAccordionPanel from './Components/SubAccordionPanel/SubAccordionPanel';

class AccordionPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job: props.job,
      collapse: false
    }
  }

  toggle = () => {
    this.setState(prevState => {
      return { collapse: !prevState.collapse }
    });
  }

  render() {
    return (
      <li className='accordion__panel' key={this.state.job.title + this.state.job.company}>
        <div className={'accordion__panel__main' + (this.state.collapse ? ' active' : '')} onClick={this.toggle}>
          <h2 className='accordion__panel__title custom-display-3'>{this.state.job.title}</h2>
          <span className='accordion__panel__company'>{this.state.job.company}</span>
          <span className='accordion__panel__location'>{this.state.job.location}</span>
          <span className='accordion__panel__duration'>{this.state.job.startDate.toLocaleDateString("en-US", { month: 'short', year: 'numeric'})} — {this.state.job.endDate === 'Present' ? this.state.job.endDate : this.state.job.endDate.toLocaleDateString("en-US", { month: 'short', year: 'numeric'})}</span>
        </div>
        {
          this.state.job.projects !== undefined ? (
            <Collapse isOpen={this.state.collapse}>
              <div className='accordion__panel__body projects'>
                <ul className='subaccordion'>
                {
                  this.state.job.projects.map(project => {
                    return (
                      <SubAccordionPanel 
                        project={project}
                        key={project.company}/>
                    );
                  })
                }
                </ul>
              </div>  
            </Collapse>
            ) : ''
          }
          {
            this.state.job.descriptions !== undefined ? (
              <AccordionDescription
              job={this.state.job}
              collapse={this.state.collapse} />
            ) : ''
          }
      </li>
    )
  }
}

export default AccordionPanel;