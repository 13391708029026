import React, {Component} from 'react';
import './NavigationBar.css';
import zenscroll from 'zenscroll';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faBuilding, faFileCode } from '@fortawesome/free-regular-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

class NavigationBar extends Component {
  scroll = (section) => {
    zenscroll.toY(this.props.sectionHeights[section], 750);
  }

  render() {
    return (
      <nav id='navigation-bar'>
        <div className='navigation-bar__section'>
          <div className='navigation-bar__section__icon main-icon'>[=]</div>
        </div>
        <div className={'navigation-bar__section' + (this.props.sectionActive === 0 ? ' active' : '')} onClick={() => this.scroll(0)}>
          {/* <a href="#overview" onClick={() => this.scroll(0)}> */}
            <div className='navigation-bar__section__icon'><FontAwesomeIcon icon={faHome} size="lg" color='#e7e7e7' /></div>
            <span className='navigation-bar__section__title'>Overview</span>
          {/* </a> */}
        </div>
        <div className={'navigation-bar__section' + (this.props.sectionActive === 1 ? ' active' : '')} onClick={() => this.scroll(1)}>
          {/* <a href="#portfolio" onClick={() => this.scroll(1)}> */}
            <div className='navigation-bar__section__icon'><FontAwesomeIcon icon={faFolderOpen} size="lg" color='#e7e7e7' /></div>
            <span className='navigation-bar__section__title'>Portfolio</span>
          {/* </a> */}
        </div>
        <div className={'navigation-bar__section' + (this.props.sectionActive === 2 ? ' active' : '')} onClick={() => this.scroll(2)}>
          {/* <a href="#experiences" onClick={() => this.scroll(2)}> */}
            <div className='navigation-bar__section__icon'><FontAwesomeIcon icon={faBuilding} size="lg" color='#e7e7e7' /></div>
            <span className='navigation-bar__section__title'>Experiences</span>
          {/* </a> */}
        </div>
        <div className={'navigation-bar__section' + (this.props.sectionActive === 3 ? ' active' : '')} onClick={() => this.scroll(3)}>
          {/* <a href="#skills" onClick={() => this.scroll(3)}> */}
            <div className='navigation-bar__section__icon'><FontAwesomeIcon icon={faFileCode} size="lg" color='#e7e7e7' /></div>
            <span className='navigation-bar__section__title'>Skills</span>
          {/* </a> */}
        </div>
        <div className='navigation-bar__section linkedin'>
          <div className='navigation-bar__section__icon--linkedin' onClick={() => window.open('https://www.linkedin.com/in/benjamin-you/', '_blank')}><FontAwesomeIcon icon={faLinkedin} size="2x" color='#e7e7e7' /></div>
        </div>
      </nav>
    );
  }
}

export default NavigationBar;