import React, { Component } from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

import NavigationBar from './Components/NavigationBar/NavigationBar';
import MainContainer from './Components/MainContainer/MainContainer';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionHeights: [0],
      sectionActive: 0,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.changeMenu);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.changeMenu);
  }

  trackSectionHeights = element => {
    if (element) {
      this.setState(prevState => {
        let sum = element.getBoundingClientRect().height;
        sum += prevState.sectionHeights[prevState.sectionHeights.length-1];
        
        return {
          sectionHeights: prevState.sectionHeights.concat(Math.floor(sum))
        }
      })
    }
  };

  changeMenu = () => {
    let positionY = window.pageYOffset;
    for (let sectionNumber = 0; sectionNumber < this.state.sectionHeights.length; sectionNumber++) {
      if (this.state.sectionHeights[sectionNumber] <= positionY && positionY < this.state.sectionHeights[sectionNumber+1]) {
        if (this.state.sectionActive !== sectionNumber) {
          this.setState({sectionActive: sectionNumber});
        }
      }
    }

  }

  render() {
    return (
      <div className="App">
        <div className='mobile-navigation-bar__section__icon--linkedin'>
          <div onClick={() => window.open('https://www.linkedin.com/in/benjamin-you/', '_blank')}><FontAwesomeIcon icon={faLinkedin} size="2x" color='#e7e7e7' /></div>
        </div>
        <NavigationBar
          sectionHeights={this.state.sectionHeights}
          sectionActive={this.state.sectionActive} />
        <MainContainer 
          trackSectionHeights={this.trackSectionHeights} />
      </div>
    );
  }
}

export default App;
