import React from 'react';
import './CategoryBox.css';

const CategoryBox = (props) => {
  let {category, columns, subcategories} = props;
  return (
    <div className='Category'>
      <div className='title'>
        { 
          // eslint-disable-next-line 
          <a href='#'><h5>{category}</h5></a>
        }
      </div>
      {
        subcategories !== undefined ? (
          <div className='body'>
            <ul className={'columns' + columns}>
              {
                subcategories.map(subcategory => 
                  // eslint-disable-next-line
                  <li><a href='#'><span>{subcategory.title}</span></a></li>
                )
              }
            </ul>
          </div>
        ) : ''
      }
      
    </div>
  );
}

export default CategoryBox;