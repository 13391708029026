const options = [
  {
    category: 'community',
    columns: 2,
    subcategories: [
      {
        title: 'activities'
      },
      {
        title: 'artists'
      },
      {
        title: 'childcare'
      },
      {
        title: 'classes'
      },
      {
        title: 'events'
      },
      {
        title: 'general'
      },
      {
        title: 'groups'
      },
      {
        title: 'local news'
      },
      {
        title: 'lost+found'
      },
      {
        title: 'missed connections'
      },
      {
        title: 'musicians'
      },
      {
        title: 'pets'
      },
      {
        title: 'politics'
      },
      {
        title: 'rants & raves'
      },
      {
        title: 'rideshare'
      },
      {
        title: 'volunteers'
      },
    ]
  },
  {
    category: 'services',
    columns: 2,
    subcategories: [
      {
        title: 'automotive'
      },
      {
        title: 'beauty'
      },
      {
        title: 'cell/mobile'
      },
      {
        title: 'computer'
      },
      {
        title: 'creative'
      },
      {
        title: 'cycle'
      },
      {
        title: 'event'
      },
      {
        title: 'farm+garden'
      },
      {
        title: 'financial'
      },
      {
        title: 'household'
      },
      {
        title: 'labor/move'
      },
      {
        title: 'legal'
      },
      {
        title: 'lessions'
      },
      {
        title: 'marine'
      },
      {
        title: 'pet'
      },
      {
        title: 'real estate'
      },
      {
        title: 'skilled trade'
      },
      {
        title: 'sm biz ads'
      },
      {
        title: 'travek/vac'
      },
      {
        title: 'write/ed/tran'
      },
    ]
  },
  {
    category: 'dicussion forums',
    columns: 3,
    subcategories: [
      {
        title: 'android'
      },
      {
        title: 'apple'
      },
      {
        title: 'arts'
      },
      {
        title: 'atheist'
      },
      {
        title: 'autos'
      },
      {
        title: 'beauty'
      },
      {
        title: 'bikes'
      },
      {
        title: 'celebs'
      },
      {
        title: 'comp'
      },
      {
        title: 'cosmos'
      },
      {
        title: 'diet'
      },
      {
        title: 'divorce'
      },
      {
        title: 'dying'
      },
      {
        title: 'eco'
      },
      {
        title: 'feedbk'
      },
      {
        title: 'film'
      },
      {
        title: 'fixit'
      },
      {
        title: 'food'
      },
      {
        title: 'frugal'
      },
      {
        title: 'gaming'
      },
      {
        title: 'garden'
      },
      {
        title: 'haiku'
      },
      {
        title: 'help'
      },
      {
        title: 'history'
      },
      {
        title: 'housing'
      },
      {
        title: 'jobs'
      },
      {
        title: 'jokes'
      },
      {
        title: 'legal'
      },
      {
        title: 'linux'
      },
      {
        title: 'manners'
      },
      {
        title: 'marriage'
      },
      {
        title: 'money'
      },
      {
        title: 'music'
      },
      {
        title: 'open'
      },
      {
        title: 'outdoor'
      },
      {
        title: 'parent'
      },
      {
        title: 'pets'
      },
      {
        title: 'philos'
      },
      {
        title: 'photo'
      },
      {
        title: 'politics'
      },
      {
        title: 'psych'
      },
      {
        title: 'recover'
      },
      {
        title: 'religion'
      },
      {
        title: 'rofo'
      },
      {
        title: 'science'
      },
      {
        title: 'spirit'
      },
      {
        title: 'sports'
      },
      {
        title: 'super'
      },
      {
        title: 'tax'
      },
      {
        title: 'travel'
      },
      {
        title: 'tv'
      },
      {
        title: 'vegan'
      },
      {
        title: 'words'
      },
      {
        title: 'writing'
      },
    ]
  },
  {
    category: 'housing',
    columns: 1,
    subcategories: [
      {
        title: 'apts / housing'
      },
      {
        title: 'housing swap'
      },
      {
        title: 'housing wanted'
      },
      {
        title: 'office / commercial'
      },
      {
        title: 'parking / storage'
      },
      {
        title: 'real estate for sale'
      },
      {
        title: 'rooms / shared'
      },
      {
        title: 'rooms wanted'
      },
      {
        title: 'sublets / temporary'
      },
      {
        title: 'vacation rentals'
      },
    ]
  },
  {
    category: 'for sale',
    columns: 2,
    subcategories: [
      {
        title: 'antiques'
      },
      {
        title: 'appliances'
      },
      {
        title: 'arts+crafts'
      },
      {
        title: 'atv/utv/sno'
      },
      {
        title: 'auto parts'
      },
      {
        title: 'aviation'
      },
      {
        title: 'baby+kid'
      },
      {
        title: 'barter'
      },
      {
        title: 'beauty+hlth'
      },
      {
        title: 'bike parts'
      },
      {
        title: 'bikes'
      },
      {
        title: 'boat parts'
      },
      {
        title: 'boats'
      },
      {
        title: 'books'
      },
      {
        title: 'business'
      },
      {
        title: 'cars+trucks'
      },
      {
        title: 'cds/dvd/vhs'
      },
      {
        title: 'cell phones'
      },
      {
        title: 'clothes+acc'
      },
      {
        title: 'collectibles'
      },
      {
        title: 'computer parts'
      },
      {
        title: 'computers'
      },
      {
        title: 'electronics'
      },
      {
        title: 'farm+garden'
      },
      {
        title: 'free'
      },
      {
        title: 'furniture'
      },
      {
        title: 'garage sale'
      },
      {
        title: 'general'
      },
      {
        title: 'heavy equip'
      },
      {
        title: 'household'
      },
      {
        title: 'jewelry'
      },
      {
        title: 'materials'
      },
      {
        title: 'motorcycle parts'
      },
      {
        title: 'motorcycles'
      },
      {
        title: 'music instr'
      },
      {
        title: 'photo+video'
      },
      {
        title: 'rvs+camp'
      },
      {
        title: 'sporting'
      },
      {
        title: 'tickets'
      },
      {
        title: 'tools'
      },
      {
        title: 'toy+games'
      },
      {
        title: 'trailers'
      },
      {
        title: 'video gaming'
      },
      {
        title: 'wanted'
      },
      {
        title: 'wheels+tires'
      },
    ]
  },
  {
    category: 'jobs',
    columns: 1,
    subcategories: [
      {
        title: 'accounting+finance'
      },
      {
        title: 'admin / office'
      },
      {
        title: 'arch / engineering'
      },
      {
        title: 'art / media / design'
      },
      {
        title: 'biotech / science'
      },
      {
        title: 'business / mgmt'
      },
      {
        title: 'customer service'
      },
      {
        title: 'education'
      },
      {
        title: 'etc / misc'
      },
      {
        title: 'food / bev / hosp'
      },
      {
        title: 'general labor'
      },
      {
        title: 'government'
      },
      {
        title: 'human resources'
      },
      {
        title: 'legal / paralegal'
      },
      {
        title: 'manufacturing'
      },
      {
        title: 'marketing / pr / ad'
      },
      {
        title: 'nedical / health'
      },
      {
        title: 'nonprofit sector'
      },
      {
        title: 'real estate'
      },
      {
        title: 'retail / wholesale'
      },
      {
        title: 'sales / biz dev'
      },
      {
        title: 'salon / spa / fitness'
      },
      {
        title: 'security'
      },
      {
        title: 'skill trade / craft'
      },
      {
        title: 'software / qa / dba'
      },
      {
        title: 'systems / network'
      },
      {
        title: 'technical support'
      },
      {
        title: 'transport'
      },
      {
        title: 'tv / film / video'
      },
      {
        title: 'web / info design'
      },
      {
        title: 'writing / editing'
      },
    ]
  },
  {
    category: 'gigs',
    columns: 2,
    subcategories: [
      {
        title: 'computer'
      },
      {
        title: 'creative'
      },
      {
        title: 'crew'
      },
      {
        title: 'domestic'
      },
      {
        title: 'event'
      },
      {
        title: 'labor'
      },
      {
        title: 'talent'
      },
      {
        title: 'writing'
      },
    ]
  },
  {
    category: 'resumes'
  }
]

export default options;