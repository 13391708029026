import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div id='footer'>
      Made with <span role='img' aria-label='heart' style={{paddingLeft: '0.25rem'}}>❤️</span>, React.JS, and (╯°□°）╯︵ ┻━┻
    </div>
  );
}

export default Footer;