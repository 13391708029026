import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Calendar from 'react-calendar';

import './Craigslist.css';

import options from './Data/options';

import CategoryBox from './Components/CategoryBox/CategoryBox';
import Header from './Components/Header/Header';
import Resources from './Components/Resources/Resources';

class Craigslist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      isDesktop: window.innerWidth > 992,
    }
  }

  onChange = date => this.setState({ date })

  componentDidMount() {
    document.title = "Craigslist Redesign Sample";
    window.scrollTo(0, 0);
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate = () => {
    this.setState({ isDesktop: window.innerWidth > 992 });
  }

  render() {
    return (
      <div className="craigslist">
        <div className='error-message'>
          There's no functionality <span role='img' aria-label='no'>🙅‍♂️</span>
        </div>
        <Header />
        <Container>
          <Row>
            <Col xs='12' lg='3'>
              <div className='left-bar'>
                <h5>create a posting</h5>
                <Calendar
                  onChange={this.onChange}
                  value={this.state.date}
                  calendarType={'US'}
                />
                {
                  this.state.isDesktop ? (<Resources />) : ''
                }
              </div>
            </Col>
            <Col xs='12' lg='9'>
              <Container fluid className='nopadding'>
                <Row>
                  <Col xs='12' md='4'>
                    {
                      options.slice(0,3).map(option => 
                        <CategoryBox
                          key={option.category}
                          category={option.category}
                          columns={option.columns}
                          subcategories={option.subcategories} />
                      )
                    }
                  </Col>
                  <Col xs='12' md='4'>
                    {
                      options.slice(3,5).map(option => 
                        <CategoryBox
                          key={option.category}
                          category={option.category}
                          columns={option.columns}
                          subcategories={option.subcategories} />
                      )
                    }
                  </Col>
                  <Col xs='12' md='4'>
                    {
                      options.slice(5,8).map(option => 
                        <CategoryBox
                          key={option.category}
                          category={option.category}
                          columns={option.columns}
                          subcategories={option.subcategories} />
                      )
                    }
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          {
            !this.state.isDesktop ?
            (
              <Row>
                <Col>
                  <Resources />
                </Col>
              </Row>
            ) : ''
          }
        </Container>
        <footer>
          <ul className="clfooter">
            <li>© <span className="desktop">craigslist</span><span className="mobile">CL</span></li>
            <li><a href="https://www.craigslist.org/about/help/">help</a></li>
            <li><a href="https://www.craigslist.org/about/scams">safety</a></li>
            <li className="desktop"><a href="https://www.craigslist.org/about/privacy.policy">privacy</a></li>
            <li className="desktop"><a href="https://forums.craigslist.org/?forumID=8">feedback</a></li>
            <li className="desktop"><a href="https://www.craigslist.org/about/craigslist_is_hiring">cl jobs</a></li>
            <li><a href="//www.craigslist.org/about/terms.of.use.en">terms</a><sup className="neu">new</sup></li>
            <li><a href="https://www.craigslist.org/about/">about</a></li>
          </ul>
        </footer>
      </div>
    );
  }
}

export default Craigslist;
