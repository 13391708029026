import React, {Component} from 'react';

import './ExperienceSection.css';
import '../Section.css';

import AccordionPanel from '../../AccordionPanel/AccordionPanel';

import experienceData from '../../../Data/experience';

class ExperienceSection extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {;
    return (
      <section id='experiences' ref={this.props.trackSectionHeights}>
        <div className='section__title'>Experiences</div>
        <ul className='accordion'>
          {
            experienceData.jobs.map((job) => {
              return (
                <AccordionPanel
                  job={job}
                  key={job.title}/>
              );
            })
          }
        </ul>
      </section>
    );
  }
}

export default ExperienceSection;