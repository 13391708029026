const technicalSkills = [
  {
    category: 'Front-End',
    skills: [
      {
        key: 'html5',
        label: 'HTML5',
        icon: 'devicon-html5-plain colored',
      },
      {
        key: 'css3',
        label: 'CSS3',
        icon: 'devicon-css3-plain colored',
      },
      {
        key: 'less',
        label: 'LESS',
        icon: 'devicon-less-plain-wordmark colored',
      },
      {
        key: 'bootstrap',
        label: 'Bootstrap',
        icon: 'devicon-bootstrap-plain colored',
      },
      {
        key: 'foundation',
        label: 'Foundation',
        icon: 'devicon-foundation-plain colored',
      },
    ]
  },
  {
    category: 'Cloud Services',
    skills: [
      {
        key: 'aws',
        label: 'AWS',
        icon: 'devicon-amazonwebservices-original colored',
      },
    ]
  },
  {
    category: 'Back-End',
    skills: [
      {
        key: 'csharp',
        label: 'C#',
        icon: 'devicon-csharp-plain colored',
      },
      {
        key: 'java',
        label: 'Java',
        icon: 'devicon-java-plain colored',
      },
      {
        key: 'python',
        label: 'Python',
        icon: 'devicon-python-plain colored',
      },
    ]
  },
  {
    category: 'Javascript',
    skills: [
      {
        key: 'javascript',
        label: 'JavaScript',
        icon: 'devicon-javascript-plain colored',
      },
      {
        key: 'typescript',
        label: 'TypeScript',
        icon: 'devicon-typescript-plain colored',
      },
      {
        key: 'angularjs',
        label: 'AngularJS',
        icon: 'devicon-angularjs-plain colored',
      },
      {
        key: 'nodejs',
        label: 'Node.JS',
        icon: 'devicon-nodejs-plain colored',
      },
      {
        key: 'reactjs',
        label: 'React.JS',
        icon: 'devicon-react-original colored',
      },
    ]
  },
  {
    category: 'Frameworks',
    skills: [
      {
        key: 'dotnet',
        label: 'ASP.NET',
        icon: 'devicon-dot-net-plain colored',
      },
    ]
  }
];

export default technicalSkills;