import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import App from '../../App';
import BCG from '../../Portfolio/bcg/BCG';
import Craigslist from '../../Portfolio/craigslist/Craigslist';

const Main = () => <App/>;
const BCGPortfolio = () => <BCG/>;
const CraigslistPortfolio = () => <Craigslist/>;

const AppRouter = () => (
  <Router>
    <div>
      <Switch>
        <Route path="/" exact component={Main} />
        <Route path="/portfolio/bcg-dv-career-redesign-sample" component={BCGPortfolio} />
        <Route path="/portfolio/craigslist-redesign-sample" component={CraigslistPortfolio} />
      </Switch>
    </div>
  </Router>
);

export default AppRouter;