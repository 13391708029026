import React, {Component} from 'react';

import './OverviewSection.css';
import '../Section.css';

class OverviewSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: this.createSteps()
    }
  }

  determineBoxSizeByWidth() {
    if (window.innerWidth > 1200) {
      return 60;
    }
    else {
      return 40;
    }
  }

  createSteps() {
    const boxLimitY = 8;
    let boxLimitX = boxLimitY;
    const boxSize = this.determineBoxSizeByWidth();
    let steps = [];
    let brightness = 0;

    for (let y = 0; y < boxLimitY; y++) {
      for (let x = 0; x < boxLimitX; x++) {
        brightness = (100+((y+1)*(x+1)*10));
        steps.push(
          <div className='box' key={Math.random()} style={{right: x * boxSize, bottom: y * boxSize, height: boxSize, width: boxSize, filter: 'brightness('+(brightness)+'%)'}}></div>
        )
      }
      boxLimitX--;
    }
    return steps;
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <section id='overview' ref={this.props.trackSectionHeights}>
        <div className='section-container'>
          <h1>Heyo! I'm Benjamin You, a <span className='highlight'>software developer</span> and <span className='highlight'>consultant</span> from Los Angeles.<br/>
          </h1>
          {
            this.state.steps
          }
        </div>
      </section>
    );
  }
}

export default OverviewSection;