import React, {Component} from 'react';
import './MainContainer.css';

// import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import OverviewSection from '../Section/OverviewSection/OverviewSection';
import PortfolioSection from '../Section/PortfolioSection/PortfolioSection';
import ExperienceSection from '../Section/ExperienceSection/ExperienceSection';
import SkillSection from '../Section/SkillSection/SkillSection';


class MainContainer extends Component {

  render() {
    return (
      <div id='main-container'>
        {/* <Header/> */}
        <OverviewSection 
          key={'overview'}
          trackSectionHeights={this.props.trackSectionHeights} />
        <PortfolioSection
          key={'portfolio'}
          trackSectionHeights={this.props.trackSectionHeights} />
        <ExperienceSection 
          key={'experience'}
          trackSectionHeights={this.props.trackSectionHeights} />
        <SkillSection 
          key={'skill'}
          trackSectionHeights={this.props.trackSectionHeights} />
        <Footer />
      </div>
    );
  }
}

export default MainContainer;