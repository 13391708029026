var calculateDuration = (startDate, endDate) => {
  let startDateTime = startDate.getTime();
  let endDateTime = endDate.getTime();

  let years = (60 * 60 * 24 * 365);
  let months = (60 * 60 * 24 * 31);

  let dateDifference = {
    years: Math.floor(((endDateTime - startDateTime) / 1000) / years),
    months: Math.ceil((((endDateTime - startDateTime) / 1000) % years) / months)
  };
  
  return dateDifference;
}

const experienceData = {
  jobs: [
    {
      company: 'Pariveda Solutions',
      title: 'Software Development Consultant II',
      location: 'El Segundo, CA',
      startDate: new Date(2017, 0),
      endDate: 'Present',
      duration: calculateDuration(new Date(2017, 0), new Date()),
      projects: [
        {
          company: 'HealthMarkets',
          title: 'Full-Stack Developer',
          location: 'Hurst, TX',
          descriptions: [
            'Developed a direct-to-service web application that allows consumers to self-service a quote and purchase Medicare products.',
            'Implemented web features and APIs to incorporate new products and applications to existing platform (C#, ASP.NET, React).',
            'Managed overview of the SDLC including estimations, sprint planning, requirements gathering, developing, quality assurance, and issue/risk management.',
          ],
          skills: [
            'C#',
            'ASP.NET',
            'HTML5',
            'CSS3',
            'JavaScript',
            'React.js',
            'Redux',
          ]
        },
        {
          company: 'Shea Homes',
          title: 'Full-Stack Developer',
          website: 'https://www.sheahomes.com/',
          location: 'Diamond Bar, CA',
          descriptions: [
            'Collaborated in the development and entire redesign of the Shea Homes website and user experience.',
            'Built over 20 responsive pages (desktop, tablet, mobile) and 50 reusable web Components (HTML5, CSS3, JavaScript, LESS).',
            'Implemented a new content management platform (Kentico) for the consumers and regional managers.',
          ],
          skills: [
            'C#',
            'ASP.NET',
            'HTML5',
            'CSS3',
            'LESS',
            'JavaScript',
          ]
        },
        {
          company: 'Equifax',
          title: 'Java Developer',
          location: 'Alpharetta, GA',
          descriptions: [
            'Handled JDK8 security remediations and improvements to address related enterprise needs, including dependent platform modernization work.',
            'Designed full-stack application Components (JSP, Struts2), modifications, and processes as required by specific user stories.',
          ],
          skills: [
            'Java',
            'JSP',
            'Struts2',
            'HTML5',
            'CSS3',
          ]
        },
        {
          company: 'STX Entertainment',
          title: 'AWS Developer',
          location: 'Los Angeles, CA',
          descriptions: [
            'Designed a low-cost and scalable AWS architecture to host over 30 microsites and multiple web applications and servers.',
            'Provided AWS system backup and recovery solutions for databases, source code, S3 resources, and EFS.',
          ],
          skills: [
            'AWS',
            'CloudFormation',
            'CloudFront',
            'EC2',
            'EFS',
            'Elastic Beanstalk',
            'NodeJS',
            'Route 53',
            'Python',
          ]
        },
        {
          company: 'Toyota',
          title: 'AWS Developer',
          location: 'Torrence, CA',
          descriptions: [
            'Supported the redesign of the online shopping application for Toyota consumers and AWS DevOps system architecture.',
          ],
          skills: [
            'AWS',
            'CloudFormation',
            'CloudWatch',
            'SNS',
          ]
        },   
      ]
    },
    {
      company: 'University of California, Irvine — School of Information and Computer Sciences',
      title: 'Peer Academic Advisor',
      location: 'Irvine, CA',
      startDate: new Date(2015, 3),
      endDate: new Date(2016, 11),
      duration: calculateDuration(new Date(2015, 3), new Date(2016, 11)),
      descriptions: [
        'Administered academic development programs for more than 1,700 incoming and continuing undergraduate students.',
        'Offered additional learning support through one-to-one consultations and enrichment workshops for academic and career strategies, such as time management, course planning, and school/life/work balance.',
      ]
    },
    {
      company: 'Becton, Dickenson, and Company',
      title: 'IT Leadership Program Intern',
      location: 'Franklin Lakes, NJ',
      startDate: new Date(2016, 5),
      endDate: new Date(2016, 7),
      duration: calculateDuration(new Date(2016, 5), new Date(2016, 7)),
      descriptions: [
        'Integrated Salesforce.com Lightning platform for the Sales & Marketing Team and conducted a pilot for a system migration of over 30,000 users.',
        'Developed a project management tool (Java and Apex) to analyze and filter Becton, Dickenson and Company’s account and opportunity data to enhance the product roadmap visualization planning tool for the IT Salesforce Center of Excellence.',
        'Conducted a UI/UX analysis for 2 product and contract applications, implemented a cleaner navigation for users, reducing the click interactions by 20%, and prepared templates for future application releases.',
      ]
    },
    {
      company: 'Beckman Laser Institute',
      title: 'IT Support Specialist',
      location: 'Irvine, CA',
      startDate: new Date(2015, 0),
      endDate: new Date(2016, 5),
      duration: calculateDuration(new Date(2015, 0), new Date(2016, 5)),
      // descriptions: []
    },
    {
      company: 'Beckman Laser Institute',
      title: 'Web Content Developer',
      location: 'Irvine, CA',
      startDate: new Date(2015, 2),
      endDate: new Date(2016, 5),
      duration: calculateDuration(new Date(2015, 2), new Date(2016, 5)),
      // descriptions: []
    },
    {
      company: 'Hacker Fund',
      title: 'Programming Mentor',
      location: 'Los Angeles, CA',
      startDate: new Date(2014, 11),
      endDate: new Date(2015, 0),
      duration: calculateDuration(new Date(2014, 11), new Date(2015, 0)),
      // descriptions: []
    },
    // {
    //   company: 'Francis Lewis High School - Virtual Enterprise Program',
    //   title: 'Student Teacher',
    //   location: 'New York City, NY',
    //   startDate: new Date(2013, 8),
    //   endDate: new Date(2014, 8),
    //   duration: calculateDuration(new Date(2013, 8), new Date(2014, 8)),
    //   // descriptions: []
    // },
    {
      company: 'University of California, Irvine — Student Housing',
      title: 'HTML Email Developer',
      location: 'Irvine, CA',
      startDate: new Date(2014, 0),
      endDate: new Date(2014, 5),
      duration: calculateDuration(new Date(2014, 0), new Date(2014, 5)),
      // descriptions: []
    },
    // {
    //   company: 'Little Genius II: Summer Camp',
    //   title: 'Teacher\'s Assistant',
    //   location: 'New York City, NY',
    //   startDate: new Date(2013, 5),
    //   endDate: new Date(2013, 8),
    //   duration: calculateDuration(new Date(2013, 5), new Date(2013, 8)),
    //   // descriptions: []
    // },
  ]
};

export default experienceData;