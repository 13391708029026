import React, { Component } from 'react';
import './LocationDropdown.css';

import dropdownlocations from '../../Data/dropdownlocations';

class LocationDropdown extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentSelection: dropdownlocations[0].name,
      displayDropdown: false
    }
  }

  activateDropdown = () => {
    this.setState(prevState => {
      return {
        displayDropdown: !prevState.displayDropdown
      }
    });
  }

  deactivateDropdown = () => {
    this.setState(prevState => {
      return {
        displayDropdown: false
      }
    });
  }

  changeCurrentSelection = (name) => {
    this.setState(prevState => {
      return {
        currentSelection: name,
        displayDropdown: false
      }
    });
  }

  render() {
    return (
      <div className='LocationDropdown' onMouseLeave={this.deactivateDropdown}>
        <div className={'button' + (this.state.displayDropdown ? ' active' : '')} onClick={this.activateDropdown}><span>{this.state.currentSelection}</span></div>
        <div className={'dropdown' + (this.state.displayDropdown ? ' active' : '')}>
          <ul>
            {
              dropdownlocations.map(location => (
                <li value={location.value} onClick={() => this.changeCurrentSelection(location.name)}>{location.name}</li>
              ))
            }
          </ul>
        </div>
      </div>
    )
  }
}

export default LocationDropdown;