import React, {Component} from 'react';
import './SubAccordionPanel.css';
import AccordionDescription from '../AccordionDescription/AccordionDescription';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faLink } from '@fortawesome/free-solid-svg-icons';

class SubAccordionPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project: props.project,
      collapse: false
    }
  }

  toggle = () => {
    this.setState(prevState => {
      return { collapse: !prevState.collapse }
    });
  }

  redirectLink = (url) => {
    window.open(url, "_blank");
  }

  render() {
    return (
      <li className='subaccordion__panel' key={this.state.project.title + this.state.project.company}>
        <div className={'subaccordion__panel__main' + (this.state.collapse ? ' active' : '')} onClick={this.toggle}>
          <h4 className='subaccordion__panel__title custom-display-4'>{this.state.project.title}</h4>
          <span className='subaccordion__panel__company'>{this.state.project.company}</span>
          <span className='subaccordion__panel__location'>{this.state.project.location}</span>
          {/* {
            this.state.project.website !== undefined ? (
              <div className='button-container'>
                <div className='circle-button' 
                  onClick={() => this.redirectLink(this.state.project.website)}
                  onMouseDown={e => e.stopPropagation()}>
                  <FontAwesomeIcon icon={faLink} />
                </div>
              </div>
            ) : ''
          } */}
        </div>
        <AccordionDescription
          job={this.state.project}
          collapse={this.state.collapse}
          key={this.state.project.company} />
      </li>
    );
  }
}

export default SubAccordionPanel;