import React, {Component} from 'react';
// import {Container, Row, Col} from 'reactstrap';
import { Link } from 'react-router-dom';
import './PortfolioSection.css';
import '../Section.css';

import image_SH from '../../../images/shea-homes-redesign.png';
import image_BCG from '../../../images/bcg-design.png';
import image_CL from '../../../images/craigslist-design.png';

class PortfolioSection extends Component {
  redirectLink = (url) => {
    window.open(url, "_blank");
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <section id='portfolio' ref={this.props.trackSectionHeights}>
        <div className='section__title'>Portfolio</div>
        {/* <div className='key-container'>
          <ul>
            <li className='work'>Work-Related</li>
            <li className='personal'>Personal</li>
          </ul>
        </div> */}
        {/* <Container>
          <Row>
            <Col xs='12' md='4' lg='4'>
              <div className='portfolio-container__box work' onClick={() => this.redirectLink('https://www.sheahomes.com/')}>
                <img className='portfolio-container__box__image' src={image_SH} alt="SH"/>
                <div className='portfolio-container__box__content'>
                  <div className='title'>Shea Homes Redesign</div>
                </div>
              </div>
            </Col>
            <Col xs='12' md='4' lg='4'>
              <Link to="/portfolio/bcg-dv-career-redesign-sample">
                <div className='portfolio-container__box personal'>
                  <img className='portfolio-container__box__image' src={image_BCG} alt="BCG"/>
                  <div className='portfolio-container__box__content'>
                    <div className='title'>BCG DV Career Redesign Sample</div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col xs='12' md='4' lg='4'>
              <Link to="/portfolio/craigslist-redesign-sample">
                <div className='portfolio-container__box personal'>
                  <img className='portfolio-container__box__image' src={image_CL} alt="CL"/>
                  <div className='portfolio-container__box__content'>
                    <div className='title'>Craigslist Redesign Sample</div>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
        </Container> */}
        <div className='section-content'>
          <div className='borderless-container work' onClick={() => this.redirectLink('https://www.sheahomes.com/')}>
            <img className='borderless-container__image' src={image_SH} alt="SH"/>
            <div className='borderless-container__title custom-display-3'><span>Shea Homes<br/>Redesign</span></div>
          </div>
          <Link to="/portfolio/bcg-dv-career-redesign-sample">
            <div className='borderless-container personal'>
              <img className='borderless-container__image' src={image_BCG} alt="BCG"/>
              <div className='borderless-container__title custom-display-3'><span>BCG DV Career<br/>Redesign Sample</span></div>
            </div>
          </Link>
          <Link to="/portfolio/craigslist-redesign-sample">
            <div className='borderless-container personal'>
              <img className='borderless-container__image' src={image_CL} alt="CL"/>
              <div className='borderless-container__title custom-display-3'><span>Craigslist<br/>Redesign Sample</span></div>
            </div>
          </Link>
        </div>
      </section>
    );
  }
}

export default PortfolioSection;