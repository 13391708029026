import React from 'react';
import './Resources.css';

const Resources = () => {
  return (
    <React.Fragment>
      <ul id="user_resources">
      <li><a href="https://www.craigslist.org/about/help/">help, faq, abuse, legal</a></li>
      <li><a href="https://www.craigslist.org/about/scams">avoid scams &amp; fraud</a></li>
      <li><a href="https://www.craigslist.org/about/safety">personal safety tips</a></li>
      <li><a href="//www.craigslist.org/about/terms.of.use">terms of use<sup className="neu">new</sup></a></li>
      <li><a href="https://www.craigslist.org/about/privacy.policy">privacy policy</a></li>
      <li><a href="https://www.craigslist.org/about/help/system-status">system status</a></li>
    </ul>

    <ul id="about_craigslist">
      <li><a href="https://www.craigslist.org/about/">about craigslist</a></li>
      <li><a href="https://www.craigslist.org/about/craigslist_is_hiring">craigslist is hiring in sf</a></li>
      <li><a href="https://www.craigslist.org/about/open_source">craigslist open source</a></li>
      <li><a href="http://blog.craigslist.org/">craigslist blog</a></li>
      <li><a href="https://www.craigslist.org/about/best/all/">best-of-craigslist</a></li>
      <li><a href="https://www.youtube.com/user/craigslist">craigslist TV</a></li>

      <li><a href="http://www.craigslistjoe.com/">"craigslist joe"</a></li>
      <li><a href="http://craigconnects.org/">craig connects</a></li>
    </ul>
    </React.Fragment>
  );
}

export default Resources;