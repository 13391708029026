import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './BCG.css';

class BCG extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationActive: 0,
      locationList: [
        'All Locations',
        'Atlanta',
        'Berlin',
        'Irvine',
        'London',
        'Manhattan Beach',
        'Mountain View',
        'New York',
        'San Diego',
        'Seattle',
        'Shanghai',
        'Sydney'
      ],
      locationListDisplay: false,
      locationListSelection: null,

      cohortActive: 0,
      cohortList: [
        'All Cohorts', 
        'Communication Design', 
        'Engineering', 
        'Experience Design', 
        'Growth', 
        'Operations', 
        'Portfolio Companies', 
        'Product Management', 
        'Strategic Design', 
        'Venture Architect', 
      ],
      cohortListDisplay: false,
      cohortListSelection: null,

      commitmentActive: 0,
      commitmentList: [
        'All Commitments',
        'Full Time & Contract',
        'Full-time',
        'Full-time / Part-time',
        'Intern',
        'Temporary'
      ],
      commitmentListDisplay: false,
      commitmentListSelection: null,

      mediaList: [
        {
          description: 'Tracr is an exciting and innovative platform that is setting the standard for blockchain traceability solutions and is on the lookout for bright people to join its commercial and product development teams.',
          timeStamp: '1 days'
        },
        {
          description: 'Tracr is an exciting and innovative platform that is setting the standard for blockchain traceability solutions and is on the lookout for bright people to join its commercial and product development teams.',
          timeStamp: '2 days'
        },
        {
          description: 'Tracr is an exciting and innovative platform that is setting the standard for blockchain traceability solutions and is on the lookout for bright people to join its commercial and product development teams.',
          timeStamp: '3 days'
        }
      ],
      moreMediaList: [
        
        {
          description: 'Tracr is an exciting and innovative platform that is setting the standard for blockchain traceability solutions and is on the lookout for bright people to join its commercial and product development teams.',
          timeStamp: '4 days'
        },
        {
          description: 'Tracr is an exciting and innovative platform that is setting the standard for blockchain traceability solutions and is on the lookout for bright people to join its commercial and product development teams.',
          timeStamp: '5 days'
        },
        {
          description: 'Tracr is an exciting and innovative platform that is setting the standard for blockchain traceability solutions and is on the lookout for bright people to join its commercial and product development teams.',
          timeStamp: '6 days'
        },
        {
          description: 'Tracr is an exciting and innovative platform that is setting the standard for blockchain traceability solutions and is on the lookout for bright people to join its commercial and product development teams.',
          timeStamp: '7 days'
        },
        {
          description: 'Tracr is an exciting and innovative platform that is setting the standard for blockchain traceability solutions and is on the lookout for bright people to join its commercial and product development teams.',
          timeStamp: '8 days'
        },
        {
          description: 'Tracr is an exciting and innovative platform that is setting the standard for blockchain traceability solutions and is on the lookout for bright people to join its commercial and product development teams.',
          timeStamp: '9 days'
        }
      ],
      jobList: [
        {
          id: 0,
          title: 'Associate – Personalization Strategy & Innovation for a Medical Asethetics Startup Venture in Irvine!',
          cohort: 'Portfolio Companies',
          location: 'Irvine',
          description: `BCG Digital Ventures is a corporate investment and incubation firm. We invent, build and invest in startups with the world’s most influential companies. We share risk and invest alongside our corporate and startup partners via a range of collaborative options.
          
          Founded in 2014, we have major Innovation and Investment Centers in Manhattan Beach, Berlin, London, Sydney, San Francisco and New York, as well as DV Hatches in Silicon Valley, Seattle and Mexico City, with more locations opening in the coming quarters. Our Centers and Hatches are home to a diverse range of entrepreneurs, operators and investors who are building businesses, creating and expanding markets and developing new technologies that benefit millions of people across the globe.

          From human resources and information technology to marketing and operations management, the Venture Operations Team is the internal force that drives us forward, enabling us to expand the limits of human expectation with our compelling products, services and ventures. Together, these one-of-a-kind problem-solvers direct our inner corporate structures with incomparable precision, profound analytic foresight and a deep commitment to world-class business services.

          As a Administrative Assistant (m/f) you will use your skills to
          - Take responsibility for the coordination of interviews
          - Schedule interviews and phone screens working directly with candidates and hiring managers
          - Welcome candidates at our office and ensuring the best possible candidate experience
          - Maintain and manage our database and Application Tracking System (ATS)
          - Arranging travel for visiting candidates, check travel bookings and receipts of our applicants`
        },
        {
          id: 1,
          title: 'CEO - Stealth B2B Logistics Startup',
          cohort: 'Portfolio Companies',
          location: 'Atlanta',
          description: `BCG Digital Ventures is a corporate investment and incubation firm. We invent, build and invest in startups with the world’s most influential companies. We share risk and invest alongside our corporate and startup partners via a range of collaborative options.
          
          Founded in 2014, we have major Innovation and Investment Centers in Manhattan Beach, Berlin, London, Sydney, San Francisco and New York, as well as DV Hatches in Silicon Valley, Seattle and Mexico City, with more locations opening in the coming quarters. Our Centers and Hatches are home to a diverse range of entrepreneurs, operators and investors who are building businesses, creating and expanding markets and developing new technologies that benefit millions of people across the globe.

          As a Administrative Assistant (m/f) you will use your skills to
          - Take responsibility for the coordination of interviews
          - Schedule interviews and phone screens working directly with candidates and hiring managers
          - Welcome candidates at our office and ensuring the best possible candidate experience
          - Maintain and manage our database and Application Tracking System (ATS)
          - Arranging travel for visiting candidates, check travel bookings and receipts of our applicants`
        },
        {
          id: 2,
          title: 'CEO - Stealth MedTech Startup',
          cohort: 'Portfolio Companies',
          location: 'New York',
          description: `BCG Digital Ventures is a corporate investment and incubation firm. We invent, build and invest in startups with the world’s most influential companies. We share risk and invest alongside our corporate and startup partners via a range of collaborative options.
          
          Founded in 2014, we have major Innovation and Investment Centers in Manhattan Beach, Berlin, London, Sydney, San Francisco and New York, as well as DV Hatches in Silicon Valley, Seattle and Mexico City, with more locations opening in the coming quarters. Our Centers and Hatches are home to a diverse range of entrepreneurs, operators and investors who are building businesses, creating and expanding markets and developing new technologies that benefit millions of people across the globe.

          As a Administrative Assistant (m/f) you will use your skills to
          - Take responsibility for the coordination of interviews
          - Schedule interviews and phone screens working directly with candidates and hiring managers
          - Welcome candidates at our office and ensuring the best possible candidate experience
          - Maintain and manage our database and Application Tracking System (ATS)
          - Arranging travel for visiting candidates, check travel bookings and receipts of our applicants`
        },
        {
          id: 3,
          title: 'CEO - Stealth MedTech Startup',
          cohort: 'Portfolio Companies',
          location: 'San Diego',
          description: `BCG Digital Ventures is a corporate investment and incubation firm. We invent, build and invest in startups with the world’s most influential companies. We share risk and invest alongside our corporate and startup partners via a range of collaborative options.
          
          Founded in 2014, we have major Innovation and Investment Centers in Manhattan Beach, Berlin, London, Sydney, San Francisco and New York, as well as DV Hatches in Silicon Valley, Seattle and Mexico City, with more locations opening in the coming quarters. Our Centers and Hatches are home to a diverse range of entrepreneurs, operators and investors who are building businesses, creating and expanding markets and developing new technologies that benefit millions of people across the globe.

          As a Administrative Assistant (m/f) you will use your skills to
          - Take responsibility for the coordination of interviews
          - Schedule interviews and phone screens working directly with candidates and hiring managers
          - Welcome candidates at our office and ensuring the best possible candidate experience
          - Maintain and manage our database and Application Tracking System (ATS)
          - Arranging travel for visiting candidates, check travel bookings and receipts of our applicants`
        },
        {
          id: 4,
          title: 'Contract DevOps Engineer',
          cohort: 'Engineering',
          location: 'London',
          description: `BCG Digital Ventures is a corporate investment and incubation firm. We invent, build and invest in startups with the world’s most influential companies. We share risk and invest alongside our corporate and startup partners via a range of collaborative options.
          
          Founded in 2014, we have major Innovation and Investment Centers in Manhattan Beach, Berlin, London, Sydney, San Francisco and New York, as well as DV Hatches in Silicon Valley, Seattle and Mexico City, with more locations opening in the coming quarters. Our Centers and Hatches are home to a diverse range of entrepreneurs, operators and investors who are building businesses, creating and expanding markets and developing new technologies that benefit millions of people across the globe.

          As a Administrative Assistant (m/f) you will use your skills to
          - Take responsibility for the coordination of interviews
          - Schedule interviews and phone screens working directly with candidates and hiring managers
          - Welcome candidates at our office and ensuring the best possible candidate experience
          - Maintain and manage our database and Application Tracking System (ATS)
          - Arranging travel for visiting candidates, check travel bookings and receipts of our applicants`
        },
        {
          id: 5,
          title: 'Contract Senior Back End Engineer',
          cohort: 'Engineering',
          location: 'London',
          description: `BCG Digital Ventures is a corporate investment and incubation firm. We invent, build and invest in startups with the world’s most influential companies. We share risk and invest alongside our corporate and startup partners via a range of collaborative options.
          
          Founded in 2014, we have major Innovation and Investment Centers in Manhattan Beach, Berlin, London, Sydney, San Francisco and New York, as well as DV Hatches in Silicon Valley, Seattle and Mexico City, with more locations opening in the coming quarters. Our Centers and Hatches are home to a diverse range of entrepreneurs, operators and investors who are building businesses, creating and expanding markets and developing new technologies that benefit millions of people across the globe.

          As a Administrative Assistant (m/f) you will use your skills to
          - Take responsibility for the coordination of interviews
          - Schedule interviews and phone screens working directly with candidates and hiring managers
          - Welcome candidates at our office and ensuring the best possible candidate experience
          - Maintain and manage our database and Application Tracking System (ATS)
          - Arranging travel for visiting candidates, check travel bookings and receipts of our applicants`
        }
      ],
      jobListDisplay: [],
      jobDescriptionDisplay: null,
      jobActive: null
    }
  }

  componentDidMount() {
    document.title = "BCG DV Career Redesign Sample";
    window.scrollTo(0, 0);
    
    this.setState(prevState => ({
      locationListSelection: prevState.locationList[0],
      cohortListSelection: prevState.cohortList[0],
      commitmentListSelection: prevState.commitmentList[0],
      jobListDisplay: prevState.jobList,
      jobDescriptionDisplay: prevState.jobList[0],
      jobActive: 0
    }));
  }

  hover = (dropdownName) => {
    if (dropdownName === 'location') {
      this.setState(prevState => ({
        locationListDisplay: !prevState.locationListDisplay
      }));
    }
    else if (dropdownName === 'cohort') {
      this.setState(prevState => ({
        cohortListDisplay: !prevState.cohortListDisplay
      }));
    }
    else if (dropdownName === 'commitment') {
      this.setState(prevState => ({
        commitmentListDisplay: !prevState.commitmentListDisplay
      }));
    }
  };

  leave = (dropdownName) => {
    if (dropdownName === 'location') {
      this.setState(prevState => ({
        locationListDisplay: false
      }));
    }
    else if (dropdownName === 'cohort') {
      this.setState(prevState => ({
        cohortListDisplay: false
      }));
    }
    else if (dropdownName === 'commitment') {
      this.setState(prevState => ({
        commitmentListDisplay: false
      }));
    }
  };

  change = (dropdownName, index) => {
    if (dropdownName === 'location') {
      this.setState(prevState => {
        const filteredJobList = prevState.jobList.filter(job => 
          ((job.location === prevState.locationList[index]) || index === 0) 
          && ((job.cohort === prevState.cohortList[prevState.cohortActive]) || prevState.cohortActive === 0));

        return ({
          locationListSelection: prevState.locationList[index],
          locationActive: index,
          jobListDisplay: filteredJobList,
          jobDescriptionDisplay: filteredJobList[0] ? filteredJobList[0] : null,
          jobActive: filteredJobList[0] ? 0 : null
      })});
    }
    else if (dropdownName === 'cohort') {
      this.setState(prevState => {
        const filteredJobList = prevState.jobList.filter(job => 
          ((job.location === prevState.locationList[prevState.locationActive]) || prevState.locationActive === 0) 
          && ((job.cohort === prevState.cohortList[index]) || index === 0));

        return ({
        cohortListSelection: prevState.cohortList[index],
        cohortActive: index,
        jobListDisplay: filteredJobList,
        jobDescriptionDisplay: filteredJobList[0] ? filteredJobList[0] : null,
        jobActive: filteredJobList[0] ? 0 : null
      })});
    }
    else if (dropdownName === 'commitment') {
      this.setState(prevState => ({
        commitmentListSelection: prevState.commitmentList[index],
        commitmentActive: index
      }));
    }
  };

  changeLocation = (location) => {
    this.change('location', this.state.locationList.findIndex(individualLocation => individualLocation === location));
  }

  changeCohort = (cohort) => {
    this.change('cohort', this.state.cohortList.findIndex(individualCohort => individualCohort === cohort));
  }

  addMoreMedia = () => {
    this.setState(prevState => ({
      mediaList: prevState.mediaList.concat(prevState.moreMediaList.slice(0,3)),
      moreMediaList: prevState.moreMediaList.slice(3, prevState.moreMediaList.length)
    }));
  };

  displayJobDescription = (job, index) => {
    this.setState(prevState => ({
      jobDescriptionDisplay: job,
      jobActive: index
    }));
  }

  render() {

    return (
      <div id='BCG'>
        <div className='mobile-layover'>
          <h3>Please view this on tablet or desktop for better user experience :)</h3>
        </div>
        <div className='section-title'>{this.state.jobListDisplay.length} Open Job Positions</div>
        <div className='object-top'></div>
        <div className='content'>
          <div className='sub-content'>
            <Container fluid>
              <Row>
                <Col sm="12" md="4">
                  <div className={'dropdown-panel' + (this.state.locationActive !== 0 ? ' active' : '')} style={ this.state.locationListDisplay ? { backgroundColor: '#fff', transition: 'background-color 0ms ease' } : null } onClick={() => this.hover('location')} onMouseLeave={() => this.leave('location')}>
                    <div className='category'>City</div>
                    <div className={'title' + (this.state.locationListDisplay ? ' active' : '') }>{ this.state.locationListSelection }</div>
                    {this.state.locationListDisplay ?
                    <div className='dropdown-list'>
                      <ul>
                        { this.state.locationList.map((item, index) => <li onClick={ () => this.change('location', index) }>{ item }</li>) }
                      </ul>
                    </div> : null }
                  </div>
                </Col>
                <Col sm="12" md="4">
                  <div className={'dropdown-panel' + (this.state.cohortActive !== 0 ? ' active' : '')} style={ this.state.cohortListDisplay ? { backgroundColor: '#fff', transition: 'background-color 0ms ease' } : null } onClick={() => this.hover('cohort')} onMouseLeave={() =>this.leave('cohort')}>
                    <div className='category'>Team</div>
                    <div className={'title' + (this.state.cohortListDisplay ? ' active' : '') }>{ this.state.cohortListSelection }</div>
                    {this.state.cohortListDisplay ?
                    <div className='dropdown-list'>
                      <ul>
                        { this.state.cohortList.map((item, index) => <li onClick={ () => this.change('cohort', index) }>{ item }</li>) }
                      </ul>
                    </div> : null }
                  </div>
                </Col>
                <Col sm="12" md="4">
                  <div className={'dropdown-panel' + (this.state.commitmentActive !== 0 ? ' active' : '')} style={ this.state.commitmentListDisplay ? { backgroundColor: '#fff', transition: 'background-color 0ms ease' } : null } onClick={() => this.hover('commitment')} onMouseLeave={() => this.leave('commitment')}>
                    <div className='category'>Commmitment</div>
                    <div className={'title' + (this.state.commitmentListDisplay ? ' active' : '') }>{ this.state.commitmentListSelection }</div>
                    {this.state.commitmentListDisplay ?
                    <div className='dropdown-list'>
                      <ul>
                        { this.state.commitmentList.map((item, index) => <li onClick={ () => this.change('commitment', index) }>{ item }</li>) }
                      </ul>
                    </div> : null }
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className='sub-content'>
            <Container fluid>
              <Row>
                <Col><div className='empty'/></Col>
              </Row>
            </Container>
          </div>
          <div className='sub-content'>
            <Container fluid>
              <Row>
                <Col sm="12" md='5'>
                  <Container fluid id='job-list-panel'>
                    <Row>
                      {
                        this.state.jobListDisplay.length > 0 ? this.state.jobListDisplay.map((job, index) => {
                          const className = this.state.jobActive === index ? ' active' : '';
                          return (
                          <Col sm='12'>
                            <div className={'job-card' + className} onClick={ () => this.displayJobDescription(job, index) }>
                              <div className='title'>{ job.title }</div>
                              <span className='tag' onClick={(e) => {
                                  e.stopPropagation();
                                  this.changeCohort(job.cohort);
                                }
                                }>{ job.cohort }</span>
                              <span className='tag' onClick={(e) => {
                                  e.stopPropagation();
                                  this.changeLocation(job.location);
                                }
                                }>{ job.location }</span>
                            </div>
                          </Col>
                        )}) : <Col>
                                <h1 className='title-message'>Oops! Nothing was found...</h1>
                              </Col>
                      }
                    </Row>
                  </Container>
                </Col>
                <Col sm="12" md='7'>
                  { this.state.jobDescriptionDisplay ?
                    <div id='job-description-panel'>
                      <div id='job-description-panel-top'>
                        <div style={{width: '70%', display: 'inline-block'}}>
                          <div className='title'>{ this.state.jobDescriptionDisplay.title }</div>
                          <div>
                            <span className='team'>{ this.state.jobDescriptionDisplay.cohort } – </span>
                            <span className='location'>{ this.state.jobDescriptionDisplay.location }</span>
                          </div>

                          <button className='button'><span>Apply</span></button>
                        </div>
                        <div style={{width: '30%', display: 'inline-block', verticalAlign: 'top'}}>
                          <img className='logo' src={require('./img/bcgdv-logo.svg')} alt="BCGDV logo"/>
                        </div>
                      </div>

                      <div id='job-description-panel-bottom'>
                        <p className='description'>{ this.state.jobDescriptionDisplay.description }</p>
                      </div>
                    </div> : <h1 className='title-message'>Oops!</h1>
                  }
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className='object-bottom big'></div>

        <div className='section-title'>Twitter Feed</div>
        <div className='object-top'></div>
        <div className='content'>
          <div className='sub-content'>
            <Container fluid>
              <Row>
                {
                  this.state.mediaList.map(media => (
                    <Col sm='4'>
                      <div className='media-card'>
                        <p className='description'>{ media.description }</p>
                        <p className='time-stamp'>{ media.timeStamp }</p>
                      </div>
                    </Col>
                  ))
                }
              </Row>
            </Container>
          </div>
          {
            this.state.moreMediaList.length > 0 ? 
            <div className='sub-content'>
              <div className='empty expandable' onClick={this.addMoreMedia}/>
            </div> : null
          }
        </div>
        <div className='object-bottom big'></div>
      </div>

    );
  }
}

export default BCG;
