import React, {Component} from 'react';
import {Container, Row, Col} from 'reactstrap';
import './SkillSection.css';
import '../Section.css';

import technicalSkills from '../../../Data/technicalSkills';

class SkillSection extends Component {
  shouldComponentUpdate() {
    return false;
  }
  
  render() {
    return (
      <section id='skills' ref={this.props.trackSectionHeights}>
        <div className='section__title'>Skills</div>
        {
            technicalSkills.map(category => {
              return (
                <div className='skill-category__container' key={category.category}>
                  <div className='skill-category__container__title'>{category.category}</div>
                  <div className='skill-category__container__content'>
                    <Container fluid>
                      <Row>
                        {
                          category.skills.map(skill => {
                            return (
                              <Col xs='6' md='4' lg='2'>
                                <div className='skill-card' key={skill.label}>
                                  <div className='skill-card__image__container'>
                                    <div className={'skill-card__image__container__icon ' + skill.icon}></div>
                                  </div>
                                  <div className='skill-card__label'>{skill.label}</div>
                                </div>
                              </Col>
                            );
                          })
                        }
                      </Row>
                    </Container>
                  </div>
                </div>
              );
            })
          }
      </section>
    );
  }
}

export default SkillSection;