import React from 'react';
import { Collapse } from 'reactstrap';
import './AccordionDescription.css';

const AccordionDescription = (props) => {
  return (
    <Collapse isOpen={props.collapse}>
      <div className='accordion__panel__body description'>
        {
          props.job.skills !== undefined ? (
            <React.Fragment>
              <span className='category-title'>Technology Stack</span>
              <ul className='skill-list'>
                {
                  props.job.skills.map(skill => {
                    return (
                      <li key={skill}>{skill}</li>
                    );
                  })
                }
              </ul>
            </React.Fragment>
          ) : ''
        }
        <ul className='description-list'>
          {
            props.job.descriptions.map((sentence, index) => {
              return (
                <li key={index}>{sentence}</li>
              );
            })
          }
        </ul>
      </div>
    </Collapse>
  );
}

export default AccordionDescription;